<template>
  <div
    :style="{
      backgroundColor: logoBannerBackgroundColor,
    }"
  >
    <div class="hidden md:block">
      <ContentSlider
        v-if="Array.isArray(mediaMentions) && mediaMentions.length > 4"
        :slides="mediaMentions"
        :breakpoints="breakpoints"
      >
        <template #slide="{ slide }">
          <div class="flex flex-col items-center pt-12 px-12">
            <div
              class="mb-[1.125rem] max-w-[11.25rem] mx-auto h-10 flex items-center"
            >
              <MediaInterpreter
                class="inline-block"
                :media="slide.media.value"
                disable-src-set
              />
            </div>
            <p
              class="max-w-[20.625rem] w-full text-m-lg md:text-lg text-center mb-[2.125rem]"
            >
              {{ slide.description.value }}
            </p>
          </div>
        </template>
      </ContentSlider>

      <ul
        v-else
        class="flex items-start text-center py-16 px-6 3xl:px-12 gap-x-6 justify-around"
      >
        <li
          v-for="mediaMention in mediaMentions"
          :key="mediaMention.createdAt.value"
          class="flex flex-col items-center md:max-w-[20.625rem] 3xl:max-w-[27.5rem]"
        >
          <div class="mb-[1.125rem] mx-auto h-10 flex items-center">
            <MediaInterpreter
              class="inline-block"
              :media="mediaMention.media.value"
              disable-src-set
            />
          </div>
          <p class="w-full text-m-lg md:text-lg 3xl:max-w-[20.625rem]">
            {{ mediaMention.description.value }}
          </p>
        </li>
      </ul>
    </div>

    <ContentSlider
      class="md:hidden pb-10"
      :slides="mediaMentions"
      show-pagination
    >
      <template #slide="{ slide }">
        <div class="flex flex-col items-center pt-12 px-12">
          <div
            class="mb-[1.125rem] max-w-[11.25rem] mx-auto h-10 flex items-center"
          >
            <MediaInterpreter
              class="inline-block"
              :media="slide.media.value"
              disable-src-set
            />
          </div>
          <p
            class="max-w-[20.625rem] w-full text-m-lg md:text-lg text-center mb-[2.125rem]"
          >
            {{ slide.description.value }}
          </p>
        </div>
      </template>
    </ContentSlider>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import MediaInterpreter from "@/components/atoms/MediaInterpreter";
import ContentSlider from "@/components/molecules/ContentSlider";
import type { MediaMention } from "./MediaMentions.interface";
import { useLogoBannerDetailsStore } from "@/stores/logoBannerDetails";
import { mediaBreakpointsResolver } from "@/constants/mediaBreakpointsResolver";

const { logoBannerBackgroundColor } = toRefs(useLogoBannerDetailsStore());

defineProps<{
  mediaMentions: MediaMention[];
}>();

const breakpoints = {
  [mediaBreakpointsResolver["md"]]: {
    slidesPerView: 4,
  },
};
</script>
